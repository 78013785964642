$modal-height: 95vh;
$modal-header-height: 40px;

.overlay {
  overflow: hidden;
}

.wrapper {
  max-height: $modal-height !important;
  width: 400px;
}

.header {
  height: $modal-header-height !important;
}

.content {
  max-height: calc(#{$modal-height} - #{$modal-header-height}) !important;
}
