@import '~@nats/webclient-common/lib/theme/_nats-brand-guidelines.scss';
@import '~@nats/webclient-common/lib/theme/global-styles';

.modalForm {
  height: 100%;
  padding: 15px 30px 30px 30px;
  background-color: #f0f0f0;

  label {
    font-size: 0.75rem !important;
  }

  button[type='submit'] {
    margin-top: 15px;
  }

  :global {
    [class*='FormBuilder__formControl'] {
      font-size: 0.875rem !important;
    }

    [class*='FormBuilder__errorTooltip'] {
      font-size: 12px;
    }

    [class*='FormBuilder__warningTooltip'] {
      font-size: 12px;
    }

    [type='select'] {
      height: 27px !important;
      padding-left: 4px;
    }

    label[for='messagesLabel'] {
      font-size: 15px !important;
    }

    label[for='serialCodesLabel'] {
      font-size: 15px !important;
    }
  }

  label[for='messagesLabel'] {
    font-size: 15px !important;
  }
}

.scrollableModalForm {
  overflow: scroll;
}
