:global {
    .userFacadeFromMailboxTable {
      border: 1px solid #145569 !important;
      margin: -28px 0 20px;
    }
  
    div[id^='userFacadeFromMailboxTable_'] {
      .ReactTable {
        // Prevent table from using full height of panel
        .rt-table {
          flex-basis: unset;
        }
  
        .rt-thead {
          height: 30px;
          color: black;
          background-color: #d1d1d1;
          border-bottom: 0.5px solid #bfbfbf;
        }
  
        .rt-tbody {
          min-height: 0px;
          overflow-y: scroll;
        }
  
        .rt-noData {
          top: 60px;
          color: #39474d;
          width: 100%;
          text-align: center;
        }
  
        .rt-td .addressColumn {
          padding-right: 3px;
        }
  
        .ellipsesText {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
  
        .ellipsesText:hover {
          overflow: visible;
          z-index: 1000 !important;
        }
      }
  
      .userFacadeFromMailboxTableScroll {
        height: 163px;
      }
  
      .userFacadeFromMailboxTableNoData {
        height: 95px;
        background-color: #e0e0e0;
      }
  
      .toggleInputHeader > div {
        text-align: center;
        position: relative;
        padding-left: 0px;
      }
  
      div.toggleInputHeader {
        margin-bottom: 2px;
        margin-left: 2px;
      }
  
      .toggleInput {
        margin-top: 4px;
        text-align: center;
      }
    }
  }
 