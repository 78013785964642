@import '~@nats/webclient-common/lib/theme/colors';
@import '~@nats/webclient-common/lib/theme/nats-brand-guidelines';

.adminPanel {
  height: 100%;
  background-color: $twilight-blue-40;
  color: $dark-text-font-color;
  font-family: $nats-regular-font-family;

  .topMenu {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 10px;
    background-color: $twilight-blue;
    color: white;

    .menuButton {
      padding: 0 10px;
      font-size: 70%;
    }

    .closeIcon {
      height: 25px;
      width: 35px;

      position: absolute;
      right: 0;

      cursor: pointer;

      background: {
        image: url('../../../node_modules/@nats/webclient-common/lib/images/components/close-white.svg');
        size: contain;
        repeat: no-repeat;
        position: center;
      }
    }
  }

  .panelWrapper {
    padding: 10px;
  }
}
