.confirmRemoveLicenceDialog {
  padding: 1rem;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;

  button {
    margin-right: 0.5rem;
  }

  button:last-child {
    margin-right: 0;
  }
}
