.validationErrors {
  ul {
    margin-bottom: 0;
    padding-left: 0;
  }

  li {
    list-style: none;
  }
}

.alertBox {
  padding: 15px;
  border: 1px solid #000;
  border-radius: 3px;
  margin: 0 auto 10px auto;
  font-size: 14px;
}

.dangerAlert {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.auditModalContent {
  padding: 1rem;
}

.calender {
  font-size: 10px !important;
  font-family: inherit !important;
  border-radius: 0 !important;
  box-shadow: 0 3px 3px -1px #b7b7b7;
}

.dateWrapper {
  display: flex;
  margin-bottom: 10px;
}

.dateLabel {
  width: 15%;
}

.downloadButton {
  margin-top: 15px;
}
