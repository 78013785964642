@import '~@nats/webclient-common/lib/theme/global-styles';
@import '~@nats/webclient-common/lib/components/Header/Header.module.scss';
@import '~@nats/webclient-common/lib/components/SubHeader/SubHeader.module.scss';

@import '../../theme/colors';

.appBody {
  display: flex;
  @include fixedHeightContent($header-height + $subheader-height);
  line-height: 1.5;

  color: $text-color;
  background-color: $background-color;

  .main {
    flex-basis: 0;
    flex-grow: 5;
    width: 50%;
  }

  .adminPanel {
    flex-basis: 0;
    flex-grow: 3;
  }

  .hidden {
    flex-grow: 0;
  }
}

.loadingContainer {
  background-color: #fff;
}
