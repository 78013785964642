@import '~@nats/webclient-common/lib/theme/colors';

.confirmDialog {
  padding: 1rem;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;

  button {
    margin-right: 0.5rem;
  }

  button:last-child {
    margin-right: 0;
  }
}

.validationErrors {
  ul {
    margin-bottom: 0;
    padding-left: 0;
  }

  li {
    list-style: none;
  }
}

.alertBox {
  padding: 15px;
  border: 1px solid #000;
  border-radius: 3px;
  margin: 0 auto 10px auto;
  font-size: 14px;
}

.dangerAlert {
  background-color: $danger-color-background;
  border-color: $danger-color-border;
  color: $danger-color-text;
}


