.dropzone {
  color: black;
  opacity: 0.3;
  text-align: center;
  padding: 20px;
  margin-bottom: 8px;
  border: 2px dashed #888888;
  border-radius: 5px;
  cursor: pointer;
  overflow-wrap: break-word;
}
