@import '~@nats/webclient-common/lib/theme/colors';
@import '~@nats/webclient-common/lib/theme/nats-brand-guidelines';

.section {
  color: #222;
  font-family: $nats-regular-font-family;
  line-height: 1.5;

  .sectionHeading {
    font-size: 20px;
    margin-bottom: 0.8rem;
  }

  .sectionContents {
    font-size: 0.875rem;
  }

  .contentRight {
    float: left;

    .bottom {
      margin-top: 0.4rem;
    }
  }

  .contentLeft {
    float: left;
    width: 60%;
    margin-right: 50px;
  }

  .label {
    margin-bottom: 0.125rem;
    font-size: 0.75rem;
  }

  .input {
    background-color: #fff;
    color: #495057;
    border: 1px solid $twilight-blue;
    padding: 0.25rem 0.5rem;
  }

  .description {
    min-height: 150px;
    white-space: pre-wrap;
  }
}

.spacer {
  clear: both;
}
