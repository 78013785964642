@import '~react-table/react-table';
@import '~@nats/webclient-common/lib/theme/colors';
@import '~@nats/webclient-common/lib/theme/metrics';
@import '~@nats/webclient-common/lib/theme/nats-brand-guidelines';

@mixin afterVert {
  position: absolute;
  right: 10px;
  top: 45%;
  transform: translateY(-50%);
}

$table-background-color: #525252;
$row-background-color: #1C252B;
$selected-background-color: tint($background-color, 20%);
$viewed-color: DarkGrey;
$text-color: white;
$panel-background: #1d262c;

$header-bar-height: 40px;
$filter-bar-height: 30px;
$pagination-bar-height: 40px;

$nats-medium-font-family: 'Roboto Medium', sans-serif;

  .ReactTable.clientSide {
    .rt-table {
      flex-basis: calc(100vh - #{$header-height});
    }
  }

  .ReactTable {
    font-size: 0.75em;
    color: #000;
    background-color: $panel-background;
    border: 0;
    user-select: none;
    min-height: 100%;
    max-height: 100%;

    .rt-table {
      overflow: hidden;
      flex-grow: 0;
      flex-basis: calc(100vh - #{$header-height} - #{$pagination-bar-height});

      .rt-thead {
        height: $header-bar-height;
        padding-right: 15px;
        color: #fff;
        background-color: $twilight-blue-120;

        .rt-tr {
          text-align: left;

          .rt-resizable-header {
            outline: 0;
          }

          .rt-th {
            box-shadow: none;
            display: flex;
            align-items: center;

            &:after {
              font-size: 1rem;
            }

            .rt-resizer::after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 50%;
              border-left: 1px solid $twilight-blue-80;
            }

            > div:first-child {
              padding-left: 5px;
            }
          }
          .rt-th.-sort-asc::after {
            content: '\25B4';
            @include afterVert;
          }
          .rt-th.-sort-desc::after {
            content: '\25BE';
            @include afterVert;
          }
        }
      }

      .rt-tbody {
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;

        .rt-tr-group {
          border-bottom: 0;
          flex-grow: 0;

          &:hover {
            color: $midnight-blue;
          }

          &:last-child {

            .rt-tr, .rt-td {
              max-height: 26px;
            }
          }

          .rt-td {
            cursor: pointer;
            padding: 2px 5px;
            font-family: $nats-font-family;
            font-weight: 400;
          }

          .-odd { background-color: #fff; }
          .-even { background-color: #eee; }
        }

        .selectedRow {
          color: #fff;
          background-color: $twilight-blue-80;
        }
      }
    }

    .pagination-bottom {
      box-sizing: content-box;
      background-color: $twilight-blue-120;
      color: white;

      .-pagination {
        height: $pagination-bar-height;
        border: none;
        box-shadow: none;

        .-previous, .-next {
          .-btn {
            background-color: $twilight-blue;
            color: white;
          }

          .-btn:enabled:hover {
            background-color: $twilight-blue-80;
          }
        }
      }
    }

    .rt-noData {
      top: 125px;
      color:$text-color;
      background: none;
    }

    .-filters.rt-thead {
      height: $filter-bar-height;

      .rt-th {
        border-right: 1px solid $twilight-blue-80;

        &:last-child {
          border-right: 0;
        }
      }

      input {
        width: 100%;
        margin: -15px 0 -5px 0;
        position: relative;
        z-index: 1;
        color: #fff;
        border-radius: 0;
        background-color: lighten($twilight-blue, 5%);
        border: 0;

        &:hover, &:focus, &:active {
          background-color: lighten($twilight-blue, 7.5%);
        }

        &::-webkit-input-placeholder {
          color: rgba(#fff, 0.5);
        }

        &:-ms-input-placeholder {
          color: rgba(#fff, 0.5);
        }
      }
    }

    .-loading {
      bottom: $pagination-bar-height;
      background-color: rgba($midnight-blue, 0.66);
    }

    .-loading-inner {
      span {
        > div {
          left: auto;
          margin-top: -$pagination-bar-height;

          // Loading bars
          > div > div, > div > div:before, > div > div:after {
            background-color: #fff;
          }
        }
      }
    }
  }
