@import '../../../node_modules/@nats/webclient-common/lib/theme/nats-brand-guidelines';

.sectionStyleForm {
  input {
    text-transform: uppercase;
  }

  textarea {
    text-transform: uppercase;
  }

  label[for$='SectionLabel'] {
    font-size: 20px !important;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
