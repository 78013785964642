.printMenuWrapper {
  width: 200px;
}

.printMenuList {
  width: 100%;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li.printOption {
    cursor: pointer;
    text-align: center;
    color: black;

    &.disabledOption {
      pointer-events: none;
      color: #CCC;

      &:hover {
        background-color: unset;
      }
    }

    &:hover {
      background-color: #EEE;
    }

    span {
      display: block;
      padding: 0.4em 0;
    }
  }
}
