@import '~@nats/webclient-common/lib/theme/nats-brand-guidelines';
@import '../../theme/colors';

@mixin node {
  width: 190px;
  font-family: $nats-monospace-font-family;
}
$font-size: 1rem;

.topNode {
  @include node;
  font-weight: bold;
  font-size: $font-size;
  margin-bottom: 3px;
}

.node {
  @include node;
  font-weight: normal;
  font-size: $font-size * 0.8;
  margin-left: 1rem;
  padding-left: 2px;
  border-left: 1px solid shade($text-color, 50%);
  line-height: 19.2px;
}
.node:first-of-type {
  margin-top: 2px;
}

.nodeText {
  display: inline-block;
  padding: 3px 7px;
}

$link-transition: 0.2s ease-out;

@mixin menuLink {
  display: inline-block;
  cursor: pointer;
  color: inherit;
  text-decoration: inherit;
  border-radius: 5px;
}

.link {
  @include menuLink;
  transition: $link-transition;
}

.link:hover {
  background-color: tint($background-color, 40%);
  transform: translateX(0.5rem);
  transition: $link-transition;
}

.activePageLink {
  @include menuLink;
  cursor: default;
  background-color: #437787;
}

.disabledPageLink {
  @include menuLink;
  cursor: default;
  opacity: 0.66;
}
