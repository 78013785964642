.validationErrors {
  ul {
    margin-bottom: 0;
    padding-left: 0;
  }

  li {
    list-style: none;
  }
}

.alertBox {
  padding: 15px;
  border: 1px solid #000;
  border-radius: 3px;
  margin: 0 auto 10px auto;
  font-size: 14px;
}

.dangerAlert {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

// Table formatting for all ReactTables within Organisation Form Modal
:global {
  .organisationModal .ReactTable {
    border: 1px solid #145569;
    max-height: 142px;

    .toggleInputHeader > div {
      text-align: center;
      position: relative;
      padding-left: 0px;
    }

    .toggleInput {
      text-align: center;
    }

  }

  // Prevent table from using full height of panel
  .organisationModal .ReactTable .rt-table {
    flex-basis: unset;
  }

  .organisationModal .ReactTable .rt-thead {
    height: 30px;
    color: black;
    background-color: #d1d1d1;
    border-bottom: 0.5px solid #bfbfbf;
  }

  // Allow scrolling to bottom of table
  .organisationModal .ReactTable .rt-tbody {
    min-height: 0px;
  }

  .organisationModal .ReactTable .rt-noData {
    top: 60px;
    color: #39474d;
    width: 100%;
    text-align: center;
  }

  .organisationModal .ReactTable .rt-td {
    white-space: unset;
  }
}

.organisationTableError {
  height: 95px;
  background-color: #e0e0e0;
}

.organisationDeleteButton {
  margin-top: 15px;
}

label[for="licencesLabel"] {
  font-size: 15px !important;
}
