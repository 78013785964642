.validationErrors {
  ul {
    margin-bottom: 0;
    padding-left: 0;
  }

  li {
    list-style: none;
  }
}

.alertBox {
  padding: 15px;
  border: 1px solid #000;
  border-radius: 3px;
  margin: 0 auto 10px auto;
  font-size: 14px;
}

.dangerAlert {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
